<template>
	<Header></Header>
	<div class="Main">
		<div class="MainLeft" :style="{'min-height':height + 'px'}">
			<div class="user">
				<el-upload
				    class="avatar-uploader"
				    :action="uploadUrl"
				    :show-file-list="false"
				    :on-success="successUpload"
				    :before-upload="beforeAvatarUpload"
					:headers="headers"
				  >
				    <img v-if="ruleForm.urHead != ''" :src="ruleForm.urHead" class="avatar" />
				    <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
				</el-upload>
			</div>
			<div class="name">
				{{ruleForm.urName}}
			</div>
			<div
				class="name" 
				style="color:#999;font-size: 14px;" 
				v-if="ruleForm.urAddress != ''"
				@click="onCopy"
			>
				<span><img src="../../assets/img/id.svg"></span>
				<span class="ID">{{ruleForm.urAddress}}</span>
				<span><el-icon><CopyDocument /></el-icon></span>
			</div>
			<div class="brief-text">
				{{ruleForm.urDes}}
			</div>
			<div class="Focus" @click="attsBtn('0')">
				<div class="ii">关注中</div>
				<div class="ii"><font color=red>{{ruleForm.atts}}</font></div>
			</div>
			<div class="Focus" @click="attsBtn('1')">
				<div class="ii">关注者</div>
				<div class="ii"><font color=red>{{ruleForm.follower}}</font></div>
			</div>
			<div class="Focus">
				<div class="ii">余额</div>
				<div class="ii"><font color=red>¥{{ruleForm.urBalance}}</font></div>
			</div>
			<div class="Focus">
				<div class="ii">剩余燃料</div>
				<div class="ii"><font color=red>{{ruleForm.urFuel}}</font></div>
			</div>
			<div class="button">
				<el-button @click="TopUpBtn">充值</el-button>
				<el-button @click="WithdrawBtn">提现</el-button>
				<el-button @click="exitBtn">退出</el-button>
			</div>
		</div>
		<div class="MainRight">
			<router-view/>
		</div>
	</div>
	<el-drawer
	    v-model="drawer"
	    :title="title"
	    size="20%"
		:destroy-on-close="true"
		:show-close="false"
		:close-on-press-escape="false"
	>
	    <div class="drawerCon" style="padding-bottom:0px" ref="scrollEvent" :style="{'height':drawerHeight,'overflow-y':'auto','overflow-x':'hidden'}">
			<div style="text-align:center;" v-if="rows.length == 0">
				<img src="../../assets/img/noData.png">
			</div>
			<div v-else>
				<div class="userBox" v-for="(item,index) in rows" :key="index">
					<div class="box">
						<img :src="item.urHead">
					</div>
					<div class="box">
						{{item.urName}}
					</div>
				</div>
			</div>
		</div>
		<div class="drawerFooter" style="width:20%;">
			<el-button type="primary" @click="drawer = false">取消</el-button>
		</div>
	</el-drawer>
	<Footer></Footer>
</template>

<script>
	import Icon from "@/components/Icon"
	import Header from "@/components/Header"
	import Footer from "@/components/Footer"
	import { Plus,CopyDocument } from '@element-plus/icons-vue'
	import { ElLoading } from 'element-plus'
	export default {
		components:{ Header,Footer,Icon,Plus,CopyDocument },
		data() {
			return{
				title:'关注中',
				gapTime:null,
				beforeUnloadTime:null,
				drawer:false,
				loading:false,
				loadingUpload:null,
				imageUrl:'',
				height:'',
				drawerHeight:'',
				uploadUrl:this.$uploadUrl +'common/oss/uploadTemp',
				headers:{
					token: window.localStorage.getItem("token")
				},
				urName:window.localStorage.getItem("urName"),
				urHead:window.localStorage.getItem("urHead"),
				ruleForm:{},
				rows:[],
				currentPage: 1,
				total: 0,
				pageSize:24,
			}
		},
		created(){
			let urId = window.localStorage.getItem("urId");
			if(urId == undefined || urId == null || urId == ""){
				this.$router.push({
					name:"Login"
				})
			}else{
				this.getObject();
			}
		},
		methods:{
			onCopy(){
				this.$copyText(this.ruleForm.urAddress).then(() => {
					this.$message.success("复制成功!");
				})
			},
			attsBtn(index){
				this.index = index;
				this.rows = [];
				this.drawer = true;
				this.focusOn();
			},
			focusOn(){
				let that = this;
				let index = that.index;
				let url = "user/an/getAtts";
				let data = {
					anId: window.localStorage.getItem("urId"),
					page: that.currentPage,
					limit: that.pageSize
				}
				if(index != "0"){
					url = "user/an/getList";
					data = {
						urId: window.localStorage.getItem("urId"),
						page: that.currentPage,
						limit: that.pageSize
					}
				}
				this.$axios({
					method:'post',
					url: url,
					headers: {
						token: window.localStorage.getItem("token")
					},
					data:this.$qs.stringify(data)
				})
				.then((res)=>{
					let rows = res.data.dataRoot;
					that.rows = that.rows.concat(rows);
					that.total = res.data.totalCounts;
					that.$nextTick(()=>{
						that.$refs.scrollEvent.addEventListener('scroll',that.scroll)
					})
				})
				.catch((err)=>console.log('请求失败',err))
			},
			scroll(){
				let that = this;
				const scrollTop = this.$refs.scrollEvent.scrollTop
				const windowHeight = this.$refs.scrollEvent.clientHeight
				const scrollHeight = this.$refs.scrollEvent.scrollHeight
				if (scrollTop + windowHeight >= scrollHeight ) {
					if(that.tableData.length < that.total){
						that.currentPage++;
						that.focusOn();
					}
				}
			},
			beforeAvatarUpload(file){
				const isJPG = file.type === 'image/gif' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png'
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
				}
				if (!isLt5M) {
				  this.$message.error('上传头像图片大小不能超过 5MB!');
				}
				this.loadingUpload = ElLoading.service({
					lock: true,
					text: '正在上传，请稍后...',
					background: 'rgba(0, 0, 0, 0.7)',
				})
				return isJPG && isLt5M;
			},
			successUpload(res,file){
				this.setHead(res.path);
			},
			setHead(urHead){
				let that = this;
				this.$axios({
					method:'post',
					url: 'user/ur/setHead',
					headers: {
						token: window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						urId: window.localStorage.getItem("urId"),
						urHead: urHead
					})
				})
				.then((res)=>{
					that.getObject();
					that.loadingUpload.close();
				})
				.catch((err)=>console.log('请求失败',err))
			},
			getObject(){
				let that = this;
				this.$axios({
					method:'post',
					url: 'user/ur/getHome',
					headers: {
						token: window.localStorage.getItem("token")
					},
					data:this.$qs.stringify({
						urId: window.localStorage.getItem("urId"),
						anId: window.localStorage.getItem("urId")
					})
				})
				.then((res)=>{
					that.ruleForm = res.data.data;
				})
				.catch((err)=>console.log('请求失败',err))
			},
			setUp(){
				this.dialogTableVisible = true;
			},
			exitBtn(){
				window.localStorage.removeItem("token");
				window.localStorage.removeItem("urId");
				window.localStorage.removeItem("urName");
				window.localStorage.removeItem("urHead");
				window.localStorage.removeItem("selectMenu");
				window.localStorage.removeItem("selectName");
				this.$router.push({
					name:"Home"
				})
			},
			WithdrawBtn(){
				this.$router.push({
					name:"Main.Withdraw"
				})
			},
			TopUpBtn(){
				this.$router.push({
					name:"Main.TopUp"
				})
			},
			ItemsBtn(index){
				this.ItemsIndex = index;
			},
			setHeight(){
				let h = document.documentElement.clientHeight || document.body.clientHeight;
				this.height = h - 258;
				this.drawerHeight = h - 115 + 'px';
			}
		},
		beforeMount(){
			this.setHeight();
		},
		mounted(){
			window.addEventListener('resize', this.setHeight, false)
		},
		beforeDestroy(){
			window.removeEventListener('resize',this.setHeight, false)
		}
	};
</script>

<style scoped>
	.userBox{
		margin-top:10px;
	}
	.userBox .box{
		display: inline-block;
		font-size:16px;
		font-weight:bold;
	}
	.userBox .box img{
		width:50px;
		height:50px;
		border-radius: 50%;
		vertical-align: middle;
	}
	.userBox .box:last-child{
		margin-left:10px;
	}
	.Main{
		width:100%;
		background:#fff;
	}
	.MainRight{
		width:80%;
		background:rgb(243, 243, 243);
		display: inline-block;
		vertical-align: text-top;
	}
	.MainLeft{
		width:20%;
		display: inline-block;
		background:#fff;
		padding-bottom:20px;
		vertical-align: text-top;
	}
	.MainLeft .btn{
		text-align:right;
		margin-right:50px;
		padding-top:100px;
	}
	.MainLeft .btn .Edit{
		margin-right:20px;
	}
	.MainLeft .user{
		text-align:center;
		margin-top:50px;
	} 
	.MainLeft .user img{
		width:120px;
		height:120px;
		border-radius:50%;
	}
	.MainLeft .name{
		margin-top:30px;
		padding:0px 40px;
		font-size: 16px;
		font-weight: 700;
		margin-top: 15px;
		text-align: center;
	}
	.MainLeft .name span.ID{
		width:90%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		display: inline-block;
	}
	.email{
		padding:0px 40px;
		margin-top:15px;
	}
	.emailBox{
		background: #59a6f3;
		padding: 20px 10px;
		border-radius: 5px;
		color: #fff;
	}
	.setup{
		background: #00c657;
		padding: 5px 10px;
		border-radius: 5px;
		margin-top: 10px;
		display: inline-block;
		cursor: pointer;
	}
	.brief-text{
		padding:0px 40px;
		font-size: 14px;
		color: #666;
		text-align: center;
		margin-top:15px;
		font-weight:bold;
	}
	.Focus{
		padding:0px 40px;
		margin-top:15px;
	}
	.Focus .ii{
		display: inline-block;
		width:50%;
	}
	.Focus .ii:first-child{
		font-size:18px;
		font-weight:bold;
	}
	.Focus .ii:last-child{
		text-align: right;
		color:#999;
		font-size:18px;
		font-weight:bold;
	}
	.button{
		padding:0px 40px;
		margin-top:40px;
	}
	.button .el-button{
		width:100%;
		height:50px;
		margin-left:0px;
		margin-top:15px;
		font-size:18px;
	}
</style>
